#lol{
    display: none;
}
html,body{
    overflow-x: hidden;
}
h2{
    text-size: 2vw;
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */