body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,
    popins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',popins,
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;